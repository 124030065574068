<template>
  <div class="layout-dashboard-card" :class="classes">
    <div v-if="breadcrumbs.length">
      <AppBreadcrumb :items="breadcrumbs" />
    </div>
    <div>
      <slot />
    </div>
    <div v-if="$slots.center" class="m-auto">
      <slot v-if="$slots.center" name="center" />
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

import { AppBreadcrumb } from '@oen.web.vue2/ui'
export default {
  name: 'LayoutDashboardCard',
  components: {
    AppBreadcrumb,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const classes = computed(() => {
      return {
        'layout-dashboard-card--has-breadcrumb': props.breadcrumbs.length,
      }
    })

    return {
      classes,
    }
  },
}
</script>
<style scoped>
.layout-dashboard-card {
  @apply flex flex-col;
  @apply max-w-6xl mx-auto;

  @screen sm {
    @apply pt-16;
  }

  &.layout-dashboard-card--has-breadcrumb {
    @apply pt-0;
  }
}
</style>
