<template>
  <div>
    <!-- Notifications Modal -->
    <AppModal
      v-model="model"
      :title="$t('ComponentAccountSettingsNotificationsModal.NotificationsTitleModal').value"
    >
      <template #header>
        <AppModalHeader>
          {{ $t('ComponentAccountSettingsNotificationsModal.NotificationsHeader').value }}
          <template #right>
            <AppBackButton
              :name="$t('RecipientDetailsModal.CloseTooltipText').value"
              analytics-name="account-settings-notifications-back"
              @click="handleModalDismiss"
            />
          </template>
        </AppModalHeader>
      </template>
      <div class="notifications-section">
        <h2 class="notifications-section-title">
          {{ $t('ComponentAccountSettingsNotificationsModal.NotificationsTransfers').value }}
        </h2>
        <AppDescriptionList>
          <AppDescriptionListItem analytics-name="account-settings-notifications-transfers-push">
            <template #title>
              <span class="notification-label">{{
                $t('ComponentAccountSettingsNotificationsModal.NotificationsTogglePush').value
              }}</span>
            </template>
            <template #action>
              <AppInputToggle
                v-model="transfersPushNotifications"
                analytics-name="account-settings-notifications-transfers-push"
              />
            </template>
          </AppDescriptionListItem>
        </AppDescriptionList>
      </div>
      <div class="notifications-section">
        <h2 class="notifications-section-title">
          {{ $t('ComponentAccountSettingsNotificationsModal.NotificationsPromotions').value }}
        </h2>
        <AppDescriptionList>
          <AppDescriptionListItem analytics-name="account-settings-notifications-promotions-push">
            <template #title>
              <span class="notification-label">{{
                $t('ComponentAccountSettingsNotificationsModal.NotificationsTogglePush').value
              }}</span>
            </template>
            <template #action>
              <AppInputToggle
                v-model="promotionsPushNotifications"
                analytics-name="account-settings-notifications-promotions-push"
              />
            </template>
          </AppDescriptionListItem>
        </AppDescriptionList>
        <AppDescriptionList>
          <AppDescriptionListItem analytics-name="account-settings-notifications-promotions-email">
            <template #title>
              <span class="notification-label">{{
                $t('ComponentAccountSettingsNotificationsModal.NotificationsToggleEmail').value
              }}</span>
            </template>
            <template #action>
              <AppInputToggle
                v-model="emailNotifications"
                analytics-name="account-settings-notifications-promotions-email"
              />
            </template>
          </AppDescriptionListItem>
        </AppDescriptionList>
      </div>
      <template #footer>
        <AppModalFooter>
          <AppButton
            analytics-name="account-settings-notifications-apply"
            :disabled="areSettingsUnchanged"
            :loading="updateSettings.loading"
            @click="updateNotificationSettings"
            >{{ $t('ComponentAccountSettingsNotificationsModal.ButtonApply').value }}</AppButton
          >
        </AppModalFooter>
      </template>
    </AppModal>

    <!-- Unsaved notification settings modal -->
    <AccountSettingsNotificationsConfirmationDialog
      v-model="showConfirmationDialog"
      @confirm="handleConfirmExit"
    ></AccountSettingsNotificationsConfirmationDialog>
  </div>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'

import AccountSettingsNotificationsConfirmationDialog from '@galileo/components/Views/Account/Settings/AccountSettingsNotificationsConfirmationDialog'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import {
  useVModel,
  AppBackButton,
  AppButton,
  AppDescriptionList,
  AppDescriptionListItem,
  AppInputToggle,
  AppModal,
  AppModalFooter,
  AppModalHeader,
} from '@oen.web.vue2/ui'

import { useAuthStore, useAppStore, useI18nStore, useAnalyticsStore } from '@galileo/stores'

export default {
  name: 'AccountSettingsNotificationsModal',
  components: {
    AccountSettingsNotificationsConfirmationDialog,
    AppBackButton,
    AppButton,
    AppDescriptionList,
    AppDescriptionListItem,
    AppInputToggle,
    AppModal,
    AppModalFooter,
    AppModalHeader,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()

    const customerProfile = computed(() => {
      return authStore.userProfile
    })

    const transfersPushNotifications = ref(customerProfile.value.willReceiveSmsNotifications)

    const promotionsPushNotifications = ref(customerProfile.value.willReceiveSmsMarketing)

    const emailNotifications = ref(customerProfile.value.willReceiveEmailMarketing)

    const showConfirmationDialog = ref(false)

    const handleConfirmExit = () => {
      showConfirmationDialog.value = false

      // Restore UI state according to current user profile state
      transfersPushNotifications.value = customerProfile.value.willReceiveSmsNotifications
      promotionsPushNotifications.value = customerProfile.value.willReceiveSmsMarketing
      emailNotifications.value = customerProfile.value.willReceiveEmailMarketing

      model.value = false
    }

    const handleModalDismiss = () => {
      if (areSettingsUnchanged.value) {
        model.value = false
      } else {
        showConfirmationDialog.value = true
      }
    }

    const areSettingsUnchanged = computed(() => {
      return (
        customerProfile.value.willReceiveSmsNotifications === transfersPushNotifications.value &&
        customerProfile.value.willReceiveSmsMarketing === promotionsPushNotifications.value &&
        customerProfile.value.willReceiveEmailMarketing === emailNotifications.value
      )
    })

    const updateSettings = reactive(
      usePromiseLazy(() => {
        let currentCustomerProfile = customerProfile.value

        currentCustomerProfile.willReceiveSmsNotifications = transfersPushNotifications.value
        currentCustomerProfile.willReceiveSmsMarketing = promotionsPushNotifications.value
        currentCustomerProfile.willReceiveEmailMarketing = emailNotifications.value

        return authStore.putCustomerProfile(currentCustomerProfile)
      })
    )

    const updateNotificationSettings = async () => {
      await updateSettings.exec()

      if (updateSettings.error) {
        appStore.logException('Exception during saving notifications', updateSettings.error)
        appStore.messageBoxGenericError()
      } else {
        analyticsStore.track({
          event: SEGMENT_EVENTS.NOTIFICATIONS_UPDATED,
          traits: {
            location: SEGMENT_LOCATIONS.SETTINGS,
            marketingPushEnabled: promotionsPushNotifications.value,
            marketingEmailAllowed: emailNotifications.value,
            transactionPushEnabled: transfersPushNotifications.value,
          },
        })

        model.value = false
      }
    }

    return {
      $t,
      areSettingsUnchanged,
      emailNotifications,
      handleConfirmExit,
      handleModalDismiss,
      model,
      promotionsPushNotifications,
      showConfirmationDialog,
      transfersPushNotifications,
      updateNotificationSettings,
      updateSettings,
    }
  },
}
</script>

<style scoped>
.notifications-section {
  @apply mt-6;
  @screen sm {
    @apply mt-0;
  }
  &:not(:last-child) {
    @apply mb-6;
  }
}
.notifications-section-title {
  @apply text-secondary-text type-overline mb-2;
}

.notification-label {
  @apply type-subtitle text-primary-text font-bold;
}
</style>
