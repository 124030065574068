<template>
  <AppDialog v-model="value">
    <template #header>
      <AppDialogHeader>
        <h3>
          {{ $t('ComponentAccountSettingsNotificationsConfirmationDialog.Header').value }}
        </h3>
      </AppDialogHeader>
    </template>
    <p>
      {{ $t('ComponentAccountSettingsNotificationsConfirmationDialog.Title').value }}
    </p>
    <template #footer>
      <AppDialogFooter>
        <AppButton
          theme="text"
          analytics-name="account-notification-settings-confirmation-dialog-confirm"
          @click="$emit('confirm')"
          >{{
            $t('ComponentAccountSettingsNotificationsConfirmationDialog.ButtonConfirm').value
          }}</AppButton
        >
        <AppButton
          analytics-name="account-notification-settings-confirmation-dialog-cancel"
          @click="model = false"
          >{{
            $t('ComponentAccountSettingsNotificationsConfirmationDialog.ButtonCancel').value
          }}</AppButton
        >
      </AppDialogFooter>
    </template>
  </AppDialog>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import { AppDialog, AppDialogHeader, AppDialogFooter, AppButton, useVModel } from '@oen.web.vue2/ui'

export default {
  name: 'AccountSettingsNotificationsConfirmationDialog',
  emits: ['cancel', 'confirm'],
  components: {
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
    AppButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)

    return {
      $t,
      model,
    }
  },
}
</script>

<style scoped></style>
