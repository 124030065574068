<template>
  <LayoutDashboardCard :breadcrumbs="breadcrumbs">
    <AppCard class="settings-app-card" sheet>
      <template #figureTitle>
        <AppCardFigureTitle
          :title="$t('PageAccountSettings.Title').value"
          class="notifications-figure-title"
        >
          <template #figure>
            <AppFigure>
              <AppIcon name=" ">
                <IconSettings />
              </AppIcon>
            </AppFigure>
          </template>
        </AppCardFigureTitle>
      </template>

      <AppList>
        <!-- Notifications Modal -->
        <RiaListItem
          analytics-name="account-settings-notifications"
          :title="$t('PageAccountSettings.ButtonNotifications').value"
          @itemClick="showNotificationsModal = true"
        >
          <template #figure-icon>
            <IconNotificationNew />
          </template>
        </RiaListItem>

        <!-- Language Modal -->
        <RiaListItem
          analytics-name="account-settings-language"
          :title="$t('PageAccountSettings.ButtonLanguage').value"
          @itemClick="showLanguageModal = true"
        >
          <template #figure-icon>
            <IconLanguage />
          </template>
        </RiaListItem>

        <!-- Language Modal -->
        <RiaListItem
          analytics-name="account-settings-about"
          :title="$t('PageAccountSettings.ButtonAbout').value"
          @itemClick="showAboutRiaDialog = true"
        >
          <template #figure-icon>
            <IconInformation />
          </template>
        </RiaListItem>
      </AppList>
    </AppCard>

    <AccountSettingsNotificationsModal
      v-model="showNotificationsModal"
    ></AccountSettingsNotificationsModal>

    <AppModal
      v-model="showLanguageModal"
      :title="$t('PageAccountSettings.LanguageTitleModal').value"
      @hidden="resetSelectedLanguage()"
    >
      <template #header>
        <AppModalHeader>
          {{ $t('PageAccountSettings.LanguageHeader').value }}
          <template #right>
            <AppBackButton
              analytics-name="account-settings-language-modal-back"
              @click="showLanguageModal = false"
            />
          </template>
        </AppModalHeader>
      </template>

      <AppAlert class="language-alert" theme="yellow">
        {{ $t('PageAccountSettings.LanguageChangeText').value }}
      </AppAlert>
      <AppInputRadioButtons
        v-if="languageOptions"
        :value="selectedLanguage"
        name="selectedLanguage"
        analytics-name="account-settings-language-radio-buttons"
        :options="languageOptions"
        right
        border
        @input="selectLanguage($event)"
      />

      <template #footer>
        <AppModalFooter>
          <AppButton
            analytics-name="account-settings-language-apply"
            :loading="languageLoading"
            :disabled="currentLanguage === selectedLanguage"
            @click="applyLanguage($event)"
            >{{ $t('PageAccountSettings.ButtonApply').value }}</AppButton
          >
        </AppModalFooter>
      </template>
    </AppModal>

    <AppDialog v-model="showAboutRiaDialog">
      <template #header>
        <AppDialogHeader>
          {{ $t('PageAccountSettings.AboutHeader').value }} {{ version }}
        </AppDialogHeader>
      </template>
      {{ $t('PageAccountSettings.AboutCopyrightText').value }}
      {{ copyrightYear }}
      {{ $t('PageAccountSettings.AboutCopyrightPostText').value }}
      <template #footer>
        <AppDialogFooter>
          <AppButton
            type="button"
            analytics-name="account-settings-about-ok"
            @click="showAboutRiaDialog = false"
            >{{ $t('PageAccountSettings.ButtonOk').value }}</AppButton
          >
        </AppDialogFooter>
      </template>
    </AppDialog>
  </LayoutDashboardCard>
</template>

<script>
import { computed, reactive, ref, watch } from '@vue/composition-api'
import LayoutDashboardCard from '@galileo/components/LayoutDashboardCard/LayoutDashboardCard'

import {
  useAlert,
  AppFigure,
  AppList,
  AppIcon,
  AppDialog,
  AppDialogHeader,
  AppDialogFooter,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  AppButton,
  AppCard,
  AppCardFigureTitle,
  AppAlert,
  AppInputRadioButtons,
  AppBackButton,
} from '@oen.web.vue2/ui'

import {
  IconInformation,
  IconLanguage,
  IconNotificationNew,
  IconSettings,
} from '@oen.web.vue2/icons'

import AccountSettingsNotificationsModal from '@galileo/components/Views/Account/Settings/AccountSettingsNotificationsModal'
import RiaListItem from '@galileo/components/RiaListItem/RiaListItem'

import { useAuthStore, useI18nStore, useCountriesStore, useEnvStore } from '@galileo/stores'

export default {
  name: 'AccountSettings',
  components: {
    RiaListItem,
    AccountSettingsNotificationsModal,
    AppList,
    AppFigure,
    AppIcon,
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
    AppModal,
    AppModalHeader,
    AppModalFooter,
    AppButton,
    AppCard,
    AppCardFigureTitle,
    AppAlert,
    AppInputRadioButtons,
    IconSettings,
    IconNotificationNew,
    IconLanguage,
    IconInformation,
    AppBackButton,
    LayoutDashboardCard,
  },
  setup() {
    const i18n = reactive(useI18n())
    const { $t } = i18n
    const { add } = useAlert()
    const envStore = useEnvStore()
    const countriesStore = useCountriesStore()
    const authStore = useAuthStore()

    const customerProfile = computed(() => {
      return authStore.userProfile
    })

    const showNotificationsModal = ref(false)

    const showLanguageModal = ref(false)

    const currentLanguage = ref(customerProfile.value.language)

    const selectedLanguage = ref(customerProfile.value.language)

    const languageLoading = ref(false)

    const languageOptions = computed(() => {
      return countriesStore.getAvailableLanguagesWithLabels
    })

    const selectLanguage = (language) => {
      selectedLanguage.value = language
    }

    const resetSelectedLanguage = () => {
      selectedLanguage.value = currentLanguage.value
    }

    const applyLanguage = async () => {
      const language = selectedLanguage.value
      languageLoading.value = true
      await authStore.changeLanguage(language)
      currentLanguage.value = language

      languageLoading.value = false
    }

    // Displays language changed snack alert in correct language after i18n updates
    const languageChangedMessage = computed(() => {
      return $t('PageAccountSettings.LanguageChanged').value
    })
    watch(languageChangedMessage, (languageChangedMessage) => {
      add(languageChangedMessage)
    })

    const showAboutRiaDialog = ref(false)

    const copyrightYear = new Date().getFullYear()

    const breadcrumbs = [
      {
        name: $t('PageAccountSettings.BreadcrumbNameAccount'),
        path: '/account',
      },
      {
        name: $t('PageAccountSettings.BreadcrumbNameSettings'),
        path: '/account/settings',
      },
    ]

    return {
      showNotificationsModal,
      showLanguageModal,
      showAboutRiaDialog,
      applyLanguage,
      currentLanguage,
      selectedLanguage,
      languageOptions,
      selectLanguage,
      resetSelectedLanguage,
      languageLoading,
      $t,
      version: envStore.env.VUE_APP_VERSION,
      copyrightYear,
      breadcrumbs,
    }
  },
}
</script>

<style scoped>
.notifications-figure-title {
  @apply mb-8;
}

.language-alert {
  @apply mb-6;
  @apply mt-2;
  @screen sm {
    @apply mt-0;
  }
}
.settings-app-card {
  @apply mb-12 mx-auto;
  @apply max-w-3xl;
  @apply h-auto !important;
}
</style>
